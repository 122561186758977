@import "../../node_modules/codeartisan/scss/layout/variables";

body {
  background-color: var(--color-light-background);
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: "Roboto", "Helvetica", "Open Sans", sans-serif
}

ul li {
  margin: 0 0 .5em;
}

p {
  font-size: 14px;
}

.inline-block {
  display: inline-block;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.fixed-showcase {
  position: fixed;
  top: 72px;
  left: 24px;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.rx-slider-section {
  height: calc(80vh - 64px);
  max-height: 520px;
  min-height: 480px;
  position: relative;
}

.fkd-description {
  max-width: 600px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: .01em;
  font-weight: 300;
  opacity: .54;
  text-align: center;
  padding-top: 12px;
  margin: 12px auto;
}

hr {
  display: block;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-top: none;
  border-bottom: 1px solid;
  border-color: var(--color-dark-divider);
}

.rx-slider {
  position: relative;
  display: flex;
  height: 700px;
  justify-content: center;
  align-items: center;
  color: var(--color-dark);
  overflow: hidden;
  padding-top: 0;
  background-color: transparent;
}

.rx-slider__cell {
  display: flex;
  height: auto;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cc-feature-hero {
  padding-right:48px;
}

.rx-slider__cell--left {
  justify-content: flex-end;
}

.rx-slider__cell--login {
  display: block;
  max-width: 332px;
  align-self: center;
  justify-content: center;
  flex-shrink: 1;
}
.rx-slider__cell--60 {
  max-width: 60%;
  flex: 0 0 60%;
  flex-shrink: 0;
  flex-grow: 1;
}
#rax-particles {
  position: absolute;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}
.md-button--primary.rx-button--try {
  background-color: #F68000;
}
.rx-layer-1{
  z-index: 1;
}
.rx-layer-0 {
  z-index: 0;
}
.rx-ticker__wrapper {
  animation: ticker 6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  width: 150px;
}
.rx-ticker {
  display: inline-flex;
  height: 48px;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
@keyframes ticker {
  0% {
    transform: translateY(0);
    margin-right: 0;
  }
  20% {
    transform: translateY(-50%);
    margin-right: -50px;
  }
  50% {
    transform: translateY(-50%);
    margin-right: -50px;
  }
  70% {
    transform: translateY(0);
    margin-right: 0;
  }
  100% {
    transform: translateY(0);
    margin-right: 0;
  }
}

@keyframes ticker-flat {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(-50%);
  }
  70% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.rx-typo--slider-big {
  font-size: 40px;
  color: #333;
  line-height: 56px;
}
.nav-layer {
  position: fixed;
  right: 0;
  top: -100vh;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background: #fff;
  box-shadow: 0 3px 40px rgba(0,0,0,0.1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: all .6s;
  z-index: 11;
}
.rax-highlight {
  border-radius: 14px;
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
  background-color: #fafafa;
  line-height: 18px;
}
.rx-section {
  padding: 32px 0;
  max-width: 100vw;
  overflow-x: hidden;
  @media (max-width: 599px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.rx-section--grey {
  background-color: #fafafa;
}
.swiper-slide--rex {
  flex-direction: column;
  width: 200px;
  padding: 16px 8px;
}
.rex-user__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  width: 92px;
}
.swiper-slide .rex-user__name {
  @media (max-width: 599px) {
    font-size: 14px;
  }
}
.rex-user__image > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.rx-narrow-wrapper {
  max-width: 540px;
  align-self: center;
  margin: 0 auto;
}
.rex-color-contrast {
  color: #424242;
}
.rex-color-contrast-light {
  color: rgba(255,255,255,.7);
}
.rax-cycle {
  text-align: center;
  align-self: center;
  position: relative;
}
.rax-cycle__item {
  background-color: var(--color-light-background);
  height: 156px;
  width: 156px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.rax-cycle__item--light {
  background-color: var(--color-light--card-background);
}
.rax-cycle__item::after {
  transform: translate3d(0,50%,0);
  top: calc(50% - 20px);
  position: absolute;
  height: 10px;
  right: 24px;
  content: '';
  width: 48px;
  opacity: 0;
}
.rax-cycle__item--last::after {
  content: none;
}
.rx-section.scrolled .rax-cycle__item::after {
  -webkit-transition: all .4s cubic-bezier(.57,.02,.7,.94) .1s;
  transition: all .4s cubic-bezier(.57,.02,.7,.94) .1s;
  right: -24px;
  opacity: 1;
}
.rx-ticker--sm {
  height: 24px;
}
.rx-ticker--sm .rx-ticker__item {
  line-height: 24px;
}
.rx-ticker--sm .rx-ticker__wrapper {
  width: 100px;
  animation: ticker-flat 6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.rax-shape {
  position: absolute;
  top:0;
  left: 0;
  opacity: .32;
  pointer-events: none;
  user-select: none;
}

.rx-shape--triangle-left {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 50px solid #fafafa;
  border-bottom: 25px solid transparent;
}

.triangle-left--primary {
  border-right-color: rgb(0, 101, 167);
}

[data-ready="true"] .nt-active .sv-animate{
  animation-fill-mode: both;
  animation-name: slideUpDelay, fadeIn;
  animation-duration: .4s;
}

[data-ready="true"] .nt-active .sv-animate .cls-1,
[data-ready="true"] .nt-active .sv-animate .els-1,
[data-ready="true"] .nt-active .sv-animate .dls--1 {
  /*for background*/
  transform-origin: center;
  animation: scale .6s cubic-bezier(0.4, 0, 0.2, 1) both;
  animation-delay: .3s;
}
[data-ready="true"] .nt-active .sv-animate .cls-12,
[data-ready="true"] .nt-active .sv-animate .cls-13,
[data-ready="true"] .nt-active .sv-animate .cls-14,
[data-ready="true"] .nt-active .sv-animate .cls-16,
[data-ready="true"] .nt-active .sv-animate .dls--5,
[data-ready="true"] .nt-active .sv-animate .dls--3,
[data-ready="true"] .nt-active .sv-animate .dls--4,
[data-ready="true"] .nt-active .sv-animate .dls--2,
[data-ready="true"] .nt-active .sv-animate .els-18,
[data-ready="true"] .nt-active .sv-animate .els-12,
[data-ready="true"] .nt-active .sv-animate .els-13,
[data-ready="true"] .nt-active .sv-animate .els-14,
[data-ready="true"] .nt-active .sv-animate .els-15,
[data-ready="true"] .nt-active .sv-animate .els-19,
[data-ready="true"] .nt-active .sv-animate .els-20,
[data-ready="true"] .nt-active .sv-animate .els-21,
[data-ready="true"] .nt-active .sv-animate .els-16,
[data-ready="true"] .nt-active .sv-animate .els-17 {
  transform-origin: center;
  animation: scale .6s cubic-bezier(0.4, 0, 0.2, 1) both;
  animation-delay: .5s;
}

@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.rx-feature-svg {
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
  max-height: 290px;
  overflow: hidden;
  margin-top: -20%;
  pointer-events: none;
}

.rx-feature-caption {
  max-width: 300px;
  margin: 0 auto;
}

.rx-header-animate {
  animation-duration: 2s;
}
.rx-overview {
  padding: 24px 0;
}
.rx-overview__item {
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  margin: 0 0 10px;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  transition: background-color .2s linear,border .2s linear;
  box-sizing: border-box;
  max-height: 500px;
  border: 1px solid transparent;
  p {
    margin: 0;
  }

  @media (max-width: $screen-tablet-breakpoint - 1px) {
    & {
      background-color: rgba(0,0,0,.06);
      pointer-events: none;
      max-height: 500px;
      border-color: rgba(0, 0, 0,.12)
    }
    & p {
      color: #212121;
    }
    .rx-overview--white & {
      background-color: rgb(255,255,255);
    }
  }
}


.rx-shadow__item p {
  user-select: none;
  pointer-events: none;
}

.rx-overview__item--active {
  background-color: rgba(0,0,0,.06);
  pointer-events: none;
  max-height: 500px;
  border-color: rgba(0, 0, 0,.12);
}

.rx-overview--white {
  .rx-overview__item--active {
    background-color: rgb(255,255,255);
  }

  .rx-overview__item {
    @media (max-width: $screen-tablet-breakpoint - 1px) {
      & {
        background-color: rgb(255,255,255);
      }
    }
  }
}

.rx-overview__item--active p {
  color: #212121;
}
.rx-overview--white {
  .rx-overview__item--active {
    background-color: rgb(255,255,255);
  }

  .rx-overview__item {
    @media (max-width: $screen-tablet-breakpoint - 1px) {
      & {
        background-color: rgb(255,255,255);
      }
    }
  }
}

.rx-overview__item--active p {
  color: #212121;
}
.rx-overview__item--active {
  background-color: rgba(0,0,0,.06);
  pointer-events: none;
  max-height: 500px;
  border-color: rgba(0, 0, 0,.12);
}
.rx-overview__item--light {
  p {
    color: rgba(255,255,255,1);
  }

  &.rx-overview__item--active {
    background-color: rgba(255,255,255,.1);
    border-color: rgba(255,255,255,.2);
  }
}

.rx-figure {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow-y: hidden;
}
.rx-figure .base-image {
  max-width: 100%;
  display: block;
}
.rx-figure > .img_layer {
  transition: opacity .2s linear,transform .2s ease-out;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
  left: 50%;
  top: 50%;
  padding: 8px;
  position: absolute;
  z-index: 1;
}

.rx-figure .img_layer--right {
  right: 8px;
  left: auto;
  top:20px;
  transform: translate3d(0,0,0);
  max-height: 450px;
  overflow-y: hidden;
  z-index: 2;
}
.rx-figure .img_layer--right-2 {
  right: 38px;
  left: auto;
  top:auto;
  bottom: 16px;
  transform: translate3d(0,0,0);
}

.rx-figure .img_layer--bottom-left {
  left: 0;
  top: auto;
  bottom: 8px;
  transform: translate3d(-10%,15%,0);
  z-index: 2;
}
.img_layer__img {
  background: #fff;
}
.rx-shadow {
  width: 100%;
  height:100%;
  pointer-events: none;
  position: absolute;
  top:0;
  left:0;
}
.rx-shadow__wrapper {
  position: relative;
  height:100%;
  width: 100%;
}
.rx-shadow__item {
  background-color: rgba(0,0,0,.12);
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
}

.rx-shadow__item--app-bar {
  height: 5%;
  width: 100%;
}
.rx-shadow__item--side-bar {
  width: 216px;
  left: auto;
  right:0;
  top: 5%;
  height: 100%;
}
.rx-image-wrapper {
  padding: 8px;
  z-index: 1;
}
.base-image--shadow {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}
.rx-wave {

}
.rx-wave-position {
  position: absolute;
  top: -16px;
  left: 0;
  transform: translate(55px,50px);
  z-index: 3;
}
.rx-wave__item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  border-radius: 50%;
}

.rx-wave__item--01 {
  width: 32px;
  height: 32px;
  background-color: transparent;
  z-index: 4;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
}
.rx-wave__inner {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  position: relative;
}
.rx-wave__inner-wrapper {
  position: absolute;
  top: 5px;
  left: 18px;
  height: 24px;
  overflow: hidden;
  line-height: 20px;
}
.rx-wave__text {
  background-color: var(--color-accent);
  color: #212121;
  line-height: 20px;
  padding: 1px 4px 1px 8px;
  border-radius: 0 12px 12px 0;
  position: relative;
  display: inline-block;
  width: 106px;
  transform: translateX(-100%);
  transition: transform .6s ease;
  transition-delay: 1.2s ;
  font-size: 13px;
  height: 22px;
}
.nt-active .rx-wave__text {
  transform: none;
}
.rx-wave__item--02 {
  width: 24px;
  height: 24px;
  background-color: var(--color-primary);
  z-index: 2;
}
.rx-wave__item--03 {
  width: 24px;
  height: 24px;
  background-color: var(--color-primary);
  z-index: 3;
  animation: dot3 1s alternate infinite;
}
.rx-wave--active .rx-wave__item--02 {
  animation: dot2 2s infinite;
}

@keyframes dot2 {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}
@keyframes dot3 {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.rx-layer--4-1 {
  margin-bottom: 100px;
  max-width: 400px;
  margin-left: 0;
}

.rx-figure .img_layer--4-3 {
  left: auto;
  top: auto;
  bottom: 8px;
  right: 0;
}
.rx-figure .img_layer--4-2 {
  right: 0;
  left: auto;
  overflow: hidden;
  top: 0;
  bottom: auto;
}

.rx-newsfeed__item {
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
}

.rx-newsfeed__item img {
  max-height: 24px;
  max-width: 70px;
}

@media (min-width: $screen-phone-breakpoint) and (max-width: $screen-tablet-breakpoint - 1px) {

}

@media (max-width: $screen-tablet-breakpoint - 1px) {
  .rx-slider .cc-feature-hero {
    padding-right: 0;
    text-align: center;
  }
  .rx-typo--slider-big {
    font-size: 32px;
    line-height: 48px;
  }
  .rx-section .md-typo--display-1 {
    font-size: 30px;
    line-height: 36px;
  }
  .rx-ticker__wrapper {
    width: 120px;
    text-align: left;
  }
  @keyframes ticker {
    0% {
      transform: translateY(0);
      margin-right: 0;
    }
    20% {
      transform: translateY(-50%);
      margin-right: -30px;
    }
    50% {
      transform: translateY(-50%);
      margin-right: -30px;
    }
    70% {
      transform: translateY(0);
      margin-right: 0;
    }
    100% {
      transform: translateY(0);
      margin-right: 0;
    }
  }
  [data-ready="true"] .nt-active .slideLeftFadeIn.rx-overview__item {
    animation-name: slideUpDelay, fadeIn;
  }
}

@media (max-width: $screen-phone-breakpoint - 1px) {
  .rx-faq .md-expansion__panel-body p {
    text-align: justify;
  }
}

.rx-pricing {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255);
  min-width: 100%;
}
.rx-pricing__column {
  flex-basis: 20%;
  flex-shrink: 1;
  max-width: 20%;
}
.rx-pricing__cell-wrapper {
  width: 100%;
  position: relative;
}
.rx-pricing__cell {
  min-height: 48px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-all;
  min-width: 0;
  padding: 0 16px;
}
.rx-pricing__cell--header {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}

.rx-section--review {
  overflow-y: hidden;
}

@keyframes bringUpReviews {
  0% {
    transform: translate(0,80%);
    opacity: 0;
  }
  100% {
    transform: translate(0,0%);
    opacity: 1;
  }
}

[data-ready="true"] .nt-active .bringUpReview {
  animation-fill-mode: both;
  animation-name: bringUpReviews;
}

